import React from "react";
import { useRef, useState } from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import { CommonUserTaskNew } from "../CommonUserTaskNew";
import { InputList } from "../../../components/Input/InputList";

import type {
  RefinanceFormData,
  ExtendedRefinancingDetails,
  RefinancingDetails,
} from "./types";
import type { ComponentConfig } from "../types";
import GenericButton from "../../../components/Buttons/GenericButton";
import { ApplicantCollection } from "../../../components/Applicant/ApplicantCollection";
import { ListInfo } from "../../../components/Info/ListInfo";
import { FormTextArea } from "../../../components/Input/FormTextArea";
import ErrorMessages from "../../../components/ErrorMessages/ErrorMessage";
import { containsLetters } from "../../../utils/containsLetters";
import type { TaskProps } from "../../../types/TaskProps";
import { validateOcrSweden, validateBankgiroSweden, validatePlusgiroSweden } from "@husevaag.marius/bank-validation";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: false,
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      useErrorBoundary: true,
      staleTime: 1000 * 10,
    },
  },
});

const UpdateRefinance = (props: TaskProps) => {
  const { task, trigger, save } = props;

  const noErrorMessage = "";

  const [errorMessages, setErrorMessages] = useState<string>(noErrorMessage);
  const [comment, setComment] = useState<string>(task?.data?.comment ?? "");

  const savedrefinanceDetails: ExtendedRefinancingDetails[] =
    task?.data?.refinancingDetails;
  const lastRefinanceDetailsId = savedrefinanceDetails
    ? Number.parseInt(
        // The Id of the last refinance detail that where saved
        savedrefinanceDetails[savedrefinanceDetails.length - 1].id,
      )
    : 0;

  const refinanceDetailsId = useRef(lastRefinanceDetailsId);
  const {
    refinanceDetails,
    applicantInfo,
    offer,
  } = task.context;

  let extendedRefinancingDetails: ExtendedRefinancingDetails[] = [];
  if (!savedrefinanceDetails) {
    extendedRefinancingDetails = refinanceDetails?.map(
      (details: ExtendedRefinancingDetails, index: number) => {
        refinanceDetailsId.current++;
        return {
          ...details,
          order: refinanceDetailsId.current,
          id: refinanceDetailsId.current.toString(),
        };
      },
    );
  }

  const [newRefinancingDetails, setNewRefinancingDetails] = useState<
    ExtendedRefinancingDetails[]
  >(savedrefinanceDetails ?? extendedRefinancingDetails);

  const loanDetails = {
    bankAccount: applicantInfo?.bankAccountNumber,
    finalLoanAmount: offer?.finalLoanAmount,
    totalRefinanceAmount: Number.parseFloat(
      newRefinancingDetails?.reduce((sum, detail) => {
          if (Number.isNaN(detail.amount)) {
            detail.amount = 0;
          }
          return sum + (typeof detail.amount === "string" ? 0 : detail.amount);
        }, 0)
        .toFixed(2),
    ),
  };
  let amountSum = 0;

  let formData: RefinanceFormData = {
    refinancingDetails: newRefinancingDetails?.map(
      (detail: RefinancingDetails) => {
        const { ...visibleDetails } = detail;
        return visibleDetails;
      },
    ),
    comment: comment,
  };

  const onComplete = async () => {
    if (errorMessages !== noErrorMessage) {
      return;
    }
    if (offer.finalLoanAmount - amountSum < 0) {
      setErrorMessages(
        "The refinance amount cannot be higher than total loan amount.",
      );
      return;
    }

    formData = {
      refinancingDetails: newRefinancingDetails?.map(
        (detail: RefinancingDetails) => {
          const { ...visibleDetails } = detail;
          if (visibleDetails.amount === "") {
            visibleDetails.amount = 0;
          }
          return visibleDetails;
        },
      ),
      comment: comment,
    };
    
    trigger(
      formData,
      () => {},
      (e: any) => {
        setErrorMessages(e?.detail);
      },
    );
  };

  const onSave = async () => {
    formData = {
      refinancingDetails: newRefinancingDetails?.map(
        (detail: RefinancingDetails) => {
          const { ...visibleDetails } = detail;
          return visibleDetails;
        },
      ),
      comment: comment,
    };
    save(
      formData,
      () => console.log("success"),
      (error: any) => console.error({ error }),
      true,
    );
  };

  const addNewInput = () => {
    refinanceDetailsId.current++;
    infoConfig = handleClickAddNewButton(
      refinanceDetailsId.current,
      setNewRefinancingDetails,
      infoConfig,
      handleDelete,
      setErrorMessages,
    );
  };

  const formConfig: ComponentConfig[] = [
    {
      component: (
        <ApplicantCollection
          nationalId={applicantInfo?.nationalId}
          firstName={applicantInfo?.firstName}
          lastName={applicantInfo?.lastName}
          mobileNumber={applicantInfo?.mobileNumber}
          emailAddress={applicantInfo?.emailAddress}
        />
      ),
      order: 1,
    },
    {
      component: <ListInfo title="Loan details" context={loanDetails} />,
      order: 2,
    },
    {
      component: (
        <FormTextArea
          name="Comment"
          onChange={(e: string) => setComment(e)}
          label="Comment"
          defaultValue={comment}
        />
      ),
      order: 4,
    },
    {
      component: <ErrorMessages errors={[errorMessages]} />,
      order: 7,
      hide: errorMessages === noErrorMessage,
    },
  ];
  let infoConfig: ComponentConfig[] = [];

  const handleDelete = (idToDelete: string | undefined) => {
    setErrorMessages(noErrorMessage);
    setNewRefinancingDetails((prevDetails) =>
      prevDetails?.filter((detail) => {
        return detail.id !== idToDelete;
      }),
    );
    infoConfig = removeComponent(idToDelete, infoConfig);
  };

  const removeComponent = (
    idToDelete: string | undefined,
    infoConfig: ComponentConfig[],
  ) => {
    const newInfoConfig = infoConfig.filter((config) => {
      if (config.component && React.isValidElement(config.component)) {
        if (config.component.type !== InputList) {
          return true;
        }
        const props: any = config.component.props;
        if (
          Array.isArray(props.infoLists) &&
          props.infoLists[0]?.id !== idToDelete
        ) {
          return true;
        }
      }
      return false;
    });

    return newInfoConfig;
  };

  const handleOnBlur = (
    key: string,
    e: React.FocusEvent<HTMLInputElement>,
    id: string | undefined,
    setErrorMessages: (value: React.SetStateAction<string>) => void,
    setNewRefinancingDetails: (
      value: React.SetStateAction<ExtendedRefinancingDetails[]>,
    ) => void,
  ) => {
    let value: string | number = e.target.value;
    switch (key) {
      case "amount":
        if (containsLetters(value)) {
          setErrorMessages(`Illegal characters in ${value}.`);
        } else {
          setErrorMessages("");
        }
        // Replace comma with dot, before parsing to float and rounding up to 2 decimals. E.g: 12,178 => 12.18
        value = Number(Number.parseFloat(value.replace(",", ".")).toFixed(2));
        break;
      case "paymentReference":
        if (value && !validateOcrSweden(value.toString())) {
          setErrorMessages("Invalid payment reference number");
          return;
        }
        setErrorMessages("");
        break;
      case "accountNumber":
        if (value && !validateBankgiroSweden(value.toString()) && !validatePlusgiroSweden(value.toString())) {
          setErrorMessages("Invalid account number");
          return;
        }
        setErrorMessages("");
        break;
      default:
        break;
    }

    setNewRefinancingDetails((prevDetails: ExtendedRefinancingDetails[]) => {
      const updatedDetails = prevDetails?.map((detail) => {
        if (detail.id !== id) {
          return detail;
        }
        return {
          ...detail,
          [key]: value,
        };
      });
      return updatedDetails;
    });
  };

  const handleClickAddNewButton = (
    refinanceDetailsId: number,
    setNewRefinancingDetails: (
      value: React.SetStateAction<ExtendedRefinancingDetails[]>,
    ) => void,
    infoConfig: ComponentConfig[],
    handleDelete: (id: string) => void,
    setErrorMessages: (value: React.SetStateAction<string>) => void,
  ) => {
    const newObject: ExtendedRefinancingDetails = {
      type: "loan",
      accountNumber: "",
      bankName: "",
      id: refinanceDetailsId.toString(),
      amount: 0,
      paymentReference: "",
      order: refinanceDetailsId,
      description: "",
      monthlyAmount: 0,
    };
    const { id, order, ...visibleDetails } = newObject;
    const context = visibleDetails;

    setNewRefinancingDetails((prevDetails) => [...(prevDetails ?? []), newObject]);
    const newInfoConfig: ComponentConfig[] = [
      ...infoConfig,
      {
        component: (
          <InputList
            deletable={true}
            onClick={() => handleDelete(id)}
            title={"Refinance details"}
            infoLists={[
              {
                title: "",
                id: id,
                context: context,
                onBlur: (key, e) =>
                  handleOnBlur(
                    key,
                    e,
                    id,
                    setErrorMessages,
                    setNewRefinancingDetails,
                  ),
              },
            ]}
          />
        ),
        order: order,
      },
    ];
    return newInfoConfig;
  };

  const createOverviewTab = () => {
    let inputOrder = 0;
    if (newRefinancingDetails?.length === 0) {
      refinanceDetailsId.current++;
      const emptyInputFields: RefinancingDetails = {
        type: "loan",
        accountNumber: "",
        bankName: "",
        amount: 0,
        paymentReference: "",
        description: "",
        monthlyAmount: 0,
      };
      newRefinancingDetails.push({
        ...emptyInputFields,
        id: refinanceDetailsId.current.toString(),
        order: 0,
      });
    }

    for (const [, details] of (newRefinancingDetails ?? []).entries()) {
      inputOrder++;
      const { id, order, ...visibleDetails } = details;
      const newDetails: any = visibleDetails;
      if (Number.isNaN(newDetails.amount)) {
        newDetails.amount = "";
      }
      if (typeof newDetails.amount === "string") {
        amountSum +=
          newDetails.amount === ""
            ? 0
            : Number.parseFloat(newDetails.amount);
      } else {
        amountSum += newDetails.amount;
      }

      newDetails.type = { 
        defaultValue: newDetails.type, 
        options: [
          { label: "Loan", value: "loan" },
          { label: "Card", value: "card" },
        ]
      };

      const input = (
        <InputList
          deletable={true}
          onClick={() => handleDelete(id)}
          title={"Refinance details"}
          infoLists={[
            {
              title: "",
              context: visibleDetails,
              onBlur: (key, e) =>
                handleOnBlur(
                  key,
                  e,
                  id,
                  setErrorMessages,
                  setNewRefinancingDetails,
                ),
              id: id,
            },
          ]}
        />
      );

      infoConfig.push({ component: input, order: inputOrder });
    }

    infoConfig.push({
      component: <GenericButton onClick={addNewInput} type="AddNewButton" />,
      order: inputOrder + 1,
    });
  };

  const setColumnsForUserTask = () => {
    if (infoConfig.length > 2) {
      return 2;
    }
    if (infoConfig.length === 2) {
      return 1;
    }
  };

  createOverviewTab();

  return  (
    <div>
      <CommonUserTaskNew
        handleComplete={onComplete}
        handleSave={onSave}
        infoConfig={infoConfig}
        formConfig={formConfig}
        overrideMasonryColumns={setColumnsForUserTask()}
        disablePrimary={errorMessages !== noErrorMessage}
      />
    </div>
  );
};

const UpdateRefinanceTask = (props: any) => (
  <QueryClientProvider client={queryClient}>
    <UpdateRefinance {...props} />
  </QueryClientProvider>
);

export default UpdateRefinanceTask;
