import { DecisionSectionWrapper } from "../styles";
import React, { useState } from "react";
import { UsertaskButtons, renderMessage, UsertaskTextArea, UsertaskSelect } from "../UsertaskComponents";
import { SecondaryButton } from "@stacc/flow-ui-components"
import { Icons } from "@stacc/flow-ui-components"
import { callApplicant } from "../../../services/itx.service";
import { mailTemplatesSE } from "../../../templates/emailAndSmsTemplates";
import { isStringNullOrEmpty } from "../../../utils/helperFunctions";
import { replaceVariablesInTemplateSE } from "../../../templates/templateHelpers";
import CommonUserTask from "../CommonUserTask";
import { List, ListItem, SMSConversationWrapper, CallSectionWrapper, IconWrapper, StyledDecisionSectionWrapper, StyledSpan } from "./styles";
import { GapContainer } from "../update-application/style";
import { ApplicantInfo } from "../../../components/Applicant/ApplicantInfo";
import { CommonData } from "../../../types/common";

type SmsOrMail = "" | "sms" | "mail";

const ContactApplicantSweden = ({ flow }: { flow: any}) => {
    const { smsTo, template: templateData, message: messageData, mailAndOrSms: mailAndOrSmsData } = flow?.task?.data ?? {};
    const { agentApiApplication, offer, integrations, communication } = flow?.flow?.data as CommonData ?? {};
    const { applicant, coapplicant } = agentApiApplication ?? {};

  //Fields for sms and mail
  const [to, setTo] = useState<string>(smsTo ?? "");
  const [template, setTemplate] = useState<string>(templateData ?? "");
  const [message, setMessage] = useState<string>(messageData ?? "");
  const [error, setError] = useState<any>(null);
  const [smsMailChoosen, setSmsMailChoosen] = useState<SmsOrMail>("");

  //Checkbox for sms and mail
  const [mailAndOrSms, setMailAndOrSms] = useState(mailAndOrSmsData ?? []);

  //Dropdown for call
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  //Data variables
  const data = flow.flow?.data;

  //Get applicants and merge them to one array
  const applicants = [applicant];
  if (coapplicant !== undefined) applicants.push(coapplicant);

  const smsMailOptions = [
    { label: "Choose SMS or Mail...", value: "" },
    { label: "SMS", value: "sms" },
    { label: "Mail", value: "mail" },
  ];

  const toOptions = [
    {
      label: "Choose a recipient",
      value: "",
    },
    {
      label: `${applicant?.firstName} ${applicant?.lastName}`,
      value: "applicant",
    },
    ...(applicants.length > 1
      ? [
        {
          label: `${coapplicant?.firstName} ${coapplicant?.lastName}`,
          value: "coapplicant",
        },
        {
          label: "Both",
          value: "both",
        },
      ]
      : []),
  ];

  const templateOptions = Object.keys(mailTemplatesSE)
    .filter((key) => mailTemplatesSE[key].templateType === smsMailChoosen)
    .map((key) => ({
      label: mailTemplatesSE[key].templateName,
      value: key,
      disabled: mailTemplatesSE[key].variables.includes("signingLink") && !offer,
    }));

  templateOptions.unshift({
    label: "No template / Free text",
    value: "",
    disabled: false
  });

  //Get mails with key string mailCommunication from communication
  const smsSent = integrations?.itx?.smsSent ?? [];
  const callsMade = integrations?.itx?.calls ?? [];
  const mailSent = communication?.filter((mail: any) => mail.key === "mailCommunication") ?? [];

  //Merge sms, calls and mail
  const smsAndMailSent = [...smsSent, ...callsMade, ...mailSent].sort((a, b) => new Date(Date.parse(a.timestamp)).getTime() - new Date(Date.parse(b.timestamp)).getTime());
  const smsOrMailChecked = smsMailChoosen === "sms" || smsMailChoosen === "mail";
  const messageOrTemplate = (isStringNullOrEmpty(template) && !isStringNullOrEmpty(message)) || !isStringNullOrEmpty(template);

  const canComplete = smsOrMailChecked && !isStringNullOrEmpty(to) && messageOrTemplate ? true : null;

  const handleCalling = async (number: string) => {
    try {
      await callApplicant({to: number});
      flow.trigger({ to: number, isMail: false, isSms: false, isCall: true });
    } catch (err) {
      setError(err);
      setIsDropdownVisible(false);
    }
  };

  const callButtonClicked = () => {
    //If we don't have a coapplicant, we can just call the applicant
    if (applicants.length === 1)
      handleCalling(applicant?.mobileNumber);
    else
      //If we have a coapplicant, we need to show a dropdown with the applicant and coapplicant
      setIsDropdownVisible(!isDropdownVisible);
  };

  const renderDropdown = () => {
    return (
      <List>
        {applicants.map((listedApplicant, index) => {
          return (
            <ListItem
              key={`Applicants-${index}`}
              onClick={() => handleCalling(listedApplicant?.mobileNumber)}
            >
              {listedApplicant?.firstName} {listedApplicant?.lastName}
            </ListItem>
          );
        })}
      </List>
    );
  };

  const flowTrigger = () => {
    // Check if both smsChecked and mailChecked are false
    if (smsMailChoosen !== "sms" && smsMailChoosen !== "mail") return null;

    let templateMessage = null;
    // Check if template is selected
    if (smsOrMailChecked && !isStringNullOrEmpty(template)) {
      // Get template from communicationTemplates
      const templateObject = mailTemplatesSE[template];
      templateMessage = replaceVariablesInTemplateSE(templateObject.text, templateObject.variables, data);
    }

    // Create options object with message, isMail, isSms, and isCall properties
    const options = {
      message: templateMessage ?? message,
      to,
      isMail: smsMailChoosen === "mail",
      isSms: smsMailChoosen === "sms",
      isCall: false,
    };

    // return null;
    return flow.trigger(options);
  };

  const handleSmsMailChoosen = (value: string) => {
    setSmsMailChoosen(value as SmsOrMail);
    setTemplate("");
    setMessage("");
  };

  const communicationContent = () => {
    return (
      <>
        <SMSConversationWrapper>
          {smsAndMailSent?.map((message, index) => {
            return (
              renderMessage(message, index)
            );
          })}
        </SMSConversationWrapper>
        <DecisionSectionWrapper>
          <GapContainer style={{ marginTop: "20px"}}>
            <ApplicantInfo applicant={applicant} />
            {coapplicant && <ApplicantInfo applicant={{...coapplicant, isCoApplicant: true}} />}
        </GapContainer>

          <CallSectionWrapper>
            <SecondaryButton
              style={{ display: "flex", alignItems: "center", overflow: "hidden" }}
              onClick={callButtonClicked}
            >
              Click to call <IconWrapper><Icons.Phone style={{ transform: "rotate(-125deg)" }} /></IconWrapper>
            </SecondaryButton>
            {isDropdownVisible ? renderDropdown() : null}
          </CallSectionWrapper>
          {UsertaskSelect("Send SMS or Mail", smsMailOptions, smsMailChoosen, handleSmsMailChoosen)}
          {smsOrMailChecked ? UsertaskSelect("To", toOptions, to, setTo) : null}
          {smsOrMailChecked ? UsertaskSelect("Template", templateOptions, template, setTemplate, { marginTop: "10px" }) : null}
          {smsOrMailChecked && (!template || template.length === 0) ? UsertaskTextArea(message, setMessage, "Message") : null}
          {UsertaskButtons(
            canComplete,
            flowTrigger,
            () => flow.save({ smsTo: to, template: template, message: message, mailAndOrSms: mailAndOrSms }),
          )}
          {!error ? null :
            <StyledDecisionSectionWrapper>
              <StyledSpan>Was not able to make the call. Make sure you are logged on ITX before trying again.</StyledSpan>
            </StyledDecisionSectionWrapper>
          }
        </DecisionSectionWrapper>
      </>
    );
  };

  return (
    CommonUserTask(communicationContent())
  );
};


export default ContactApplicantSweden;
