import { Icons } from "@stacc/flow-ui-components";
import { NormalInfoWrapper } from "../../../../tasks/styles";
import { OfferGivenWrapper } from "../../styles";
import { RowsWrapper } from "../../../styles";
import type { CommonData } from "../../../../../types/common";

interface OfferDetailsProps {
  data: CommonData
  status: string
}
interface Detail {
  label: string,
  value: any,
}

function getIndexOfLastRow(details: Detail[]) {
  let lastValidRow = -1;
  let secondLastValidRow = -1;
  let validCount = 0;

  for (let i = 0; i < details.length; i++) {
    if (details[i].value != null) {
      secondLastValidRow = lastValidRow;
      lastValidRow = i;
      validCount++;
    }
  }

  return validCount % 2 === 0
    ? [secondLastValidRow, lastValidRow]
    : [lastValidRow];
}

export const OfferRow = ({ data, status }: OfferDetailsProps) => {
  const finalLoanAmount = data?.offer?.finalLoanAmount || 0;
  const businessStatus = data?.businessStatus;

  // Offer valid until
  const { offer } = data || {};
  const { validUntil } = offer || {};
  const offerExpirationDate = validUntil;
  const offerExpired = offerExpirationDate ? new Date(offerExpirationDate) < new Date() : false;
  let formattedExpirationDate = offerExpired ? "Offer expired" : offerExpirationDate;
  if (offerExpirationDate) {
    formattedExpirationDate = Intl.DateTimeFormat("en-GB")
      .format(new Date(offerExpirationDate))
      .replace(/\//g, ".");
  }
  const offerValidUntilValue = businessStatus === "loan-disbursed" || offerExpired ? "Disbursed" : formattedExpirationDate;
  const offerValidUntilText = businessStatus === "loan-disbursed" || offerExpired ? "Offer expired" : "Offer valid until";


  const effectiveInterestRate = data?.offer?.effectiveInterestRate ? `${data?.offer?.effectiveInterestRate}%` : null

  const terms = data?.offer?.terms;

  const offerGivenIcon = finalLoanAmount ? (
    <Icons.SuccessCircle />
  ) : status === "active" ? (
    <Icons.ActiveCircle />
  ) : (
    <Icons.AbortedCircle />
  );

  const offerDetails = [
    { label: offerValidUntilText, value: offerValidUntilValue },
    { label: "Loan amount", value: `${new Intl.NumberFormat().format(finalLoanAmount)} kr`, },
    { label: "Effective interest rate", value: effectiveInterestRate },
    { label: "Terms", value: terms },
    {
      label: "Offer given", value: <div style={{
        position: "relative",
      }}><OfferGivenWrapper>{offerGivenIcon}</OfferGivenWrapper></div>
    }
  ]

  const lastIndexes = getIndexOfLastRow(offerDetails)

  return (
    <RowsWrapper className="Offer information">
      <NormalInfoWrapper $header>Offer Details</NormalInfoWrapper>
      {offerDetails.map((detail, index) => {
        if (detail.value === null || detail.value === undefined) return
        return (
          <NormalInfoWrapper key={`Offer row - ${detail.label}`} $isLast={lastIndexes.includes(index)}>
            <div>{detail.label}</div>
            <div>{detail.value}</div>
          </NormalInfoWrapper>
        )
      })}
    </RowsWrapper>
  )
}